import serviceGuild from "../../resources/images/service_guild.png";
import serviceElement from "../../resources/images/service_element.png";
import serviceAfricaConsulting from "../../resources/images/service_africa_consulting.png";

import { Box, SxProps, Theme, Grid, IconButton, Link } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const Service = () => {
  return (
    <Box sx={sx} className="Service">
      <Grid container className="Service-Title">
        <span className="Service-Title-Head">S</span>ERVICE
      </Grid>
      <Grid container className="Service-SubTitle">
        事業内容
      </Grid>
      <Box className="Service-Content">
        <Box className="Service-Content-ImagePc" component="img" alt="プログラマーズギルド" src={serviceGuild} />
        <Box className="Service-Content-Item">
          <Box className="Service-Content-Item-Ruby">プログラマーズギルド</Box>
          <Box className="Service-Content-Item-Title">
            Programmers
            <br className="Service-Content-Item-Title-BrSp" /> Guild
          </Box>
          <Box className="Service-Content-Item-SubTitle">チーム開発の請負サービス</Box>
          <Box className="Service-Content-Item-ImageSp" component="img" alt="プログラマーズギルド" src={serviceGuild} />
          <Box className="Service-Content-Item-Description">
            副業人材を主体とした「業務委託サービス」です。
            <br />
            通常では人材派遣の市場に出てこないような大企業に勤めているベテランのエンジニアや開発の中核を担うフリーランスエンジニアが現在約100名ほど所属しています。
            <br />
            副業を主体としているため長時間の拘束稼働はできませんが、トップレベルの知識と経験を活かして複数人でチームとなってクライアントの業務を請け負います。
            <br />
            クライアント側のチームメンバーと共同して開発を行うこともできるため、クライアント所属のエンジニアのスキルアップを行うことも可能です。
          </Box>
          <Link target="_blank" className="Service-Content-Item-Link" href="https://programmers-guild.jp/">
            <Box>MORE</Box>
            <IconButton className="Service-Content-Item-Button">
              <ArrowForwardIcon className="Service-Content-Item-Button-Icon" />
            </IconButton>
          </Link>
        </Box>
      </Box>
      <Box className="Service-Content">
        <Box className="Service-Content-ImagePc" component="img" alt="テクノエレメント" src={serviceElement} />
        <Box className="Service-Content-Item">
          <Box className="Service-Content-Item-Ruby">テクノエレメント</Box>
          <Box className="Service-Content-Item-Title">Techno Element</Box>
          <Box className="Service-Content-Item-SubTitle">フレキシブルなSES事業</Box>
          <Box className="Service-Content-Item-ImageSp" component="img" alt="テクノエレメント" src={serviceElement} />
          <Box className="Service-Content-Item-Description">
            お客さまのプロジェクトで人手が足りない場合に、「スポットで力を入れるため1ヶ月フルで稼働できるエンジニアが２、３人欲しい」、「特定のスキルを持った人材が1人が欲しい」といった、要望にも対応することができるSES事業も手がけています。
            <br />
            弊社は多くの人材をプールしているため、お客様の事業フェーズにあったご要望にフレキシブルにご提供できます。
            <br />
            事業分野もWebやクライアントアプリの開発からインフラまで、業界・ジャンルに問わずさまざまな人材をご用意しております。
            <br />
            また、現役のWeb/ITエンジニアも多数所属しているため、開発から得た知見や技術も提供することができます。結果として保守性が高く、事業の規模や今後のスケールに合わせて拡張しやすい、高品質な開発のサポートを行うことも可能です。
          </Box>
        </Box>
      </Box>
      <Box className="Service-Content">
        <Box
          className="Service-Content-ImagePc"
          component="img"
          alt="アフリカコンサルティング"
          src={serviceAfricaConsulting}
        />
        <Box className="Service-Content-Item">
          <Box className="Service-Content-Item-Ruby">アフリカコンサルティング</Box>
          <Box className="Service-Content-Item-Title">Africa Consulting</Box>
          <Box className="Service-Content-Item-SubTitle">アフリカ進出コンサルティング事業</Box>
          <Box
            className="Service-Content-Item-ImageSp"
            component="img"
            alt="アフリカコンサルティング"
            src={serviceAfricaConsulting}
          />
          <Box className="Service-Content-Item-Description">
            技術発展が激しいアフリカへの進出の手助けを行うコンサルティング事業です。
            <br />
            アフリカ54カ国全ての国に対して進出のコンサルティングが可能です。
            <br />
            アフリカに強いコネクションを持つIAA（インスパイアアフリカ協会）との協力とアフリカでの事業実績を元に現地で安全に確実な事業が行えるよう高品質なコンサルティングサポートを行います。
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.Service": {
    width: "100%",
    padding: "64px 0 92px",
  },
  ".Service-Title": {
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    fontSize: {
      md: "64px",
      xs: "40px",
    },
    lineHeight: "88px",
    letterSpacing: "0.15em",
  },
  ".Service-Title-Head": {
    color: "#22A1B9",
    fontWeight: 700,
  },
  ".Service-SubTitle": {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: {
      md: "16px",
      xs: "14px",
    },
    lineHeight: {
      md: "24x",
      xs: "20px",
    },
    letterSpacing: "1px",
  },
  ".Service-Content": {
    justifyContent: {
      md: "flex-start",
      xs: "center",
    },
    marginTop: {
      md: "100px",
      xs: "60px",
    },
    display: "flex",
    flexDirection: "row-reverse",
    "&:nth-child(odd)": {
      flexDirection: "row",
    },
  },
  ".Service-Content-ImagePc": {
    display: {
      md: "flex",
      xs: "none",
    },
    maxWidth: "740px",
    objectFit: "contain",
    objectPosition: "top",
  },
  ".Service-Content-Item": {
    margin: {
      md: "0px 120px",
      xs: "0px 32px",
    },
  },
  ".Service-Content-Item-Ruby": {
    fontWeight: 700,
    fontSize: {
      md: "16px",
      xs: "14px",
    },
    lineHeight: {
      md: "22px",
      xs: "20px",
    },
  },
  ".Service-Content-Item-Title": {
    fontWeight: 600,
    fontSize: {
      md: "46px",
      xs: "42px",
    },
    lineHeight: {
      md: "66px",
      xs: "48px",
    },
    letterSpacing: "0.05em",
    color: "#22A1B9",
  },
  ".Service-Content-Item-Title-BrSp": {
    display: {
      md: "none",
      xs: "block",
    },
  },
  ".Service-Content-Item-SubTitle": {
    fontWeight: 700,
    fontSize: {
      md: "24px",
      xs: "20px",
    },
    lineHeight: {
      md: "33px",
      xs: "27px",
    },
  },
  ".Service-Content-Item-ImageSp": {
    display: {
      md: "none",
      xs: "flex",
    },
    width: "100%",
    marginTop: "8px",
  },
  ".Service-Content-Item-Description": {
    maxWidth: {
      md: "530px",
      xs: "100%",
    },
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: {
      md: "30px",
      xs: "35px",
    },
    marginTop: "33px",
  },
  ".Service-Content-Item-Link": {
    display: "flex",
    flexDirection: "row",
    justifyContent: {
      md: "flex-start",
      xs: "flex-end",
    },
    alignItems: "center",
    marginTop: "24px",
  },
  ".Service-Content-Item-Button": {
    color: "#22A1B9",
    border: "1px solid #D9D9D9",
    marginLeft: "16px",
  },
});
